// Generated by Framer (5eef525)

import { addFonts, cx, CycleVariantState, Fetcher, RichText, useComponentViewport, useLocaleInfo, usePrefetch, usePreloadQuery, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";

const cycleOrder = ["tsnlf4_UQ"];

const serializationHash = "framer-g7KFR"

const variantClassNames = {tsnlf4_UQ: "framer-v-5nin47"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transition1 = {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}

const query = (prefetch) => prefetch({cacheDuration: 0, credentials: "same-origin", errorFallbackValue: "Error", fallbackValue: "hh", resultKeyPath: "", resultOutputType: "string", url: `https://api.teamtailor.com/v1/jobs`})

const toString = (value) => {
    return typeof value === "string" ? value : String(value);
};


const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion(React.Fragment)

const preload = async (prequery, prefetch, props) => await Promise.all([query(prefetch)])

const preloadStatements = {tsnlf4_UQ: preload}

const useLoadingVariant = (variant, gestures, clearLoadingVariant, setGestureState, props?: Record<string, unknown>) => {const prequery = usePreloadQuery()
const prefetch = usePrefetch()
React.useEffect(() => {const promise = preloadStatements[variant]
if (!promise || !gestures?.[variant]?.loading) return
promise(prequery, prefetch, props)
.then(() => clearLoadingVariant())
.catch(() => setGestureState({isError: true}))}, [variant])}

const getProps = ({height, id, width, ...props}) => { return {...props} }

const createLayoutDependency = (props, variants) => {if (props.layoutDependency) return variants.join('-') + props.layoutDependency
return variants.join('-')}

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, ...restProps} = getProps(props)

const {baseVariant, classNames, clearLoadingGesture, gestureHandlers, gestureVariant, isLoading, setGestureState, setVariant, variants} = useVariantState({cycleOrder, defaultVariant: "tsnlf4_UQ", variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

const componentViewport = useComponentViewport()

useLoadingVariant(baseVariant, undefined, clearLoadingGesture, setGestureState)

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Variants animate={variants} initial={false}><Transition value={transition1}><motion.div {...restProps} {...gestureHandlers} className={cx(serializationHash, ...sharedStyleClassNames, "framer-5nin47", className, classNames)} data-framer-name={"Variant 1"} layoutDependency={layoutDependency} layoutId={"tsnlf4_UQ"} ref={ref ?? ref1} style={{...style}}><Fetcher disabled={isLoading} requests={[{cacheDuration: 0, credentials: "same-origin", errorFallbackValue: "Error", fallbackValue: "hh", resultKeyPath: "", resultOutputType: "string", url: `https://api.teamtailor.com/v1/jobs`}]}>{fetchResult => (<RichText __fromCanvasComponent children={<React.Fragment><motion.p>hjh</motion.p></React.Fragment>} className={"framer-1lmm9n2"} fonts={["Inter"]} layoutDependency={layoutDependency} layoutId={"F1X0CAvO3"} style={{"--framer-link-text-color": "rgb(0, 153, 255)", "--framer-link-text-decoration": "underline"}} text={toString(fetchResult[0])} verticalAlignment={"top"} withExternalLayout/>)}</Fetcher></motion.div></Transition></Variants>
</LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-g7KFR.framer-dxmfr1, .framer-g7KFR .framer-dxmfr1 { display: block; }", ".framer-g7KFR.framer-5nin47 { height: 98px; overflow: hidden; position: relative; width: 210px; }", ".framer-g7KFR .framer-1lmm9n2 { bottom: 0px; flex: none; left: 0px; position: absolute; right: 0px; top: 0px; white-space: pre-wrap; word-break: break-word; word-wrap: break-word; }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 98
 * @framerIntrinsicWidth 210
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]}}}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const FramerAbIDuH5Sv: React.ComponentType<Props> = withCSS(Component, css, "framer-g7KFR") as typeof Component;
export default FramerAbIDuH5Sv;

FramerAbIDuH5Sv.displayName = "h";

FramerAbIDuH5Sv.defaultProps = {height: 98, width: 210};

addFonts(FramerAbIDuH5Sv, [{explicitInter: true, fonts: [{family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F", url: "https://framerusercontent.com/assets/5vvr9Vy74if2I6bQbJvbw7SY1pQ.woff2", weight: "400"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116", url: "https://framerusercontent.com/assets/EOr0mi4hNtlgWNn9if640EZzXCo.woff2", weight: "400"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+1F00-1FFF", url: "https://framerusercontent.com/assets/Y9k9QrlZAqio88Klkmbd8VoMQc.woff2", weight: "400"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0370-03FF", url: "https://framerusercontent.com/assets/OYrD2tBIBPvoJXiIHnLoOXnY9M.woff2", weight: "400"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF", url: "https://framerusercontent.com/assets/JeYwfuaPfZHQhEG8U5gtPDZ7WQ.woff2", weight: "400"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD", url: "https://framerusercontent.com/assets/vQyevYAyHtARFwPqUzQGpnDs.woff2", weight: "400"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB", url: "https://framerusercontent.com/assets/b6Y37FthZeALduNqHicBT6FutY.woff2", weight: "400"}]}], {supportsExplicitInterCodegen: true})